import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../components/Seo";
import Layout from "../components/layout/Layout";
import Cityscape from "../components/Cityscape";
import Lead from "../components/Lead";
import Projects from "../components/Projects";
import Events from "../components/Events";
import Reflections from "../components/Reflections";
import GetUpdates from "../components/GetUpdates";
import Quote from "../components/Quote";
import Jobs from "../components/Jobs";

const IndexPage = () => {
  return (
    <>
      <Seo title="Welcome to The Policy Lab" bodyClass="page__home" />
      <Layout>
        <div className="u__vspacing s__sky s__lil-splatter pb-0">
          <header className="main__header header u__spacing">
            <div className="brand">
              {/*<StaticImage*/}
              {/*  src="../images/bpl-shield.svg"*/}
              {/*  alt="Brown University"*/}
              {/*  layout="fixed"*/}
              {/*  width={140}*/}
              {/*  height={70}*/}
              {/*  className="brand__img"*/}
              {/*/>*/}
            </div>
          </header>
          <main id="content" className="main__content u__spacing pb-0">
            <article className="main__article intro">
              <header className="intro__header">
                <h1>
                  <span className="text-uppercase">Welcome to</span>
                  <span className="display-1">The Policy Lab</span>
                </h1>
              </header>
              <div className="intro__content u__content mx-auto">
                <p className="h4 alt-bold"></p>
              </div>
            </article>
          </main>
          <Cityscape sky={false} splatter={false} />
        </div>

        <Lead
          id="about"
          class="s__white"
          title="About The Policy Lab"
          body="The Policy Lab was active at Brown University from 2018-2023, with a focus on bringing together experts from government, universities, and community organizations to collaborate on research tailored to inform decisions about how to improve policies and programs across the state. This website is an archive of work from those days."
        />

        <Projects header="Projects" />

        <section id="events" className="u__vspacing s__light">
          <article className="u__container u__spacing events">
            <div className="row">
              <div className="col-md-3 col-lg-4">
                <h2 className="h1">Events</h2>
              </div>
              <div className="col-md-9 col-lg-8">
                <Events />
              </div>
            </div>
          </article>
        </section>

        <Reflections header="Reflections" />

        <GetUpdates />

        <Quote
          quote="Applied research can be remarkably difficult, as theories often fail to work in practice. We need scientists shoulder-to-shoulder with practitioners in those moments, co-designing fresh ideas and iteratively experimenting to optimize solutions. The Policy Lab is a place and group of people dedicated to this work. Think NASA or Bell Labs, but for public policy."
          quotee="David Yokum"
          quoteePosition="Director (2017-2023)"
        />

        <Lead
          id="team"
          class="s__light"
          title="Lab Alumni"
          body="The Policy Lab at Brown University was a community of researchers, data scientists, project managers, designers, and practitioners dedicated to promoting the use of evidence-based research in Rhode Island policymaking."
          ctaLink="/about/team"
          ctaLinkText="Alumni of The Policy Lab"
        />
      </Layout>
    </>
  );
};

export default IndexPage;
